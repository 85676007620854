import PropTypes from "prop-types";

const ContactModule = (props) => {
  const { className, ...rest } = props;

  return (
    <main
      className={`relative h-screen flex justify-center items-center bg-cover bg-center ${className}`}
      style={{ backgroundImage: "url(/images/bg1.jpg)" }}
      {...rest}
    >
      <div className="absolute inset-0 bg-primary opacity-50"></div>

      <div className="container h-full">
        <div className="relative h-full flex justify-center flex-col">
          <span className="text-5-0 leading-tight text-white">Contact us</span>

          <div className="mt-5-0 sm:mt-2-0 leading-relaxed text-white">
            <div className="flex">
              <span className="block mr-1-0">Address: </span>
              <span>
                52 Nutsubidze str, <br />
                Tbilisi,
                <br />
                0177,
                <br />
                Georgia,
                <br />
                +995 571 21 95 75
              </span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

ContactModule.propTypes = {
  className: PropTypes.string,
};

ContactModule.defaultProps = {
  className: "",
};

export default ContactModule;
