import PropTypes from "prop-types";

const Hero = (props) => {
  const { className, ...rest } = props;

  return (
    <section
      id="about"
      className={`relative h-screen bg-primary ${className}`}
      {...rest}
    >
      <video
        muted
        playsInline
        loop
        autoPlay
        className="absolute inset-0 h-full w-full object-cover"
      >
        <source src="/videos/homepage_hero.mp4" type="video/mp4"></source>
      </video>

      <div className="container mx-auto h-full">
        <div className="relative z-10 h-full flex items-center">
          <h3 className="text-7-0 sm:text-3-6 xs:text-3-0 font-bold leading-tight text-white">
            Achieve new heights
            <br />
            of your business with <br />
            Multi Task
          </h3>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
};

Hero.defaultProps = {
  className: "",
};

export default Hero;
