import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./router";
import DefaultLayout from "./layouts/default/DefaultLayout";
import "./index.css";
import "./app.css";

function App() {
  return (
    <Router>
      <DefaultLayout>
        <Routes />
      </DefaultLayout>
    </Router>
  );
}

export default App;
