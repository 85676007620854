import Service from "./Service";

const ServicesList = (props) => {
  const { ...rest } = props;

  return (
    <section id="services-list" {...rest}>
      <div className="container mx-auto">
        <div className="py-8-0 sm:py-4-0 flex justify-center sm:flex-col">
          <Service
            to="#igaming-engine"
            title="IGaming Engine"
            description="Add variety to your gaming platform, 
            create new games and expand your business with 
            our advanced low maintenance gaming engine."
            className="w-50-0 sm:w-auto"
          />
          <Service
            to="#backoffice-engine"
            title="Back Office Engine"
            description="Our modular Back office allows you to integrate your 
            platform for better management or add new and vital 
            features to existing system."
            className="pl-10-0 sm:pl-0 sm:pt-4-0 w-50-0 sm:w-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default ServicesList;
