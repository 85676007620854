import { HashLink as Link } from "react-router-hash-link";

const BottombarNavigation = (props) => {
  const { ...rest } = props;

  return (
    <div {...rest}>
      <div className="flex items-center justify-between sm:justify-center h-5-6">
        <ul className="flex sm:hidden">
          <li>
            <Link
              to="/data-policy#"
              className="hover:text-primary duration-300"
            >
              Data Policy
            </Link>
          </li>
          <li className="ml-4-5">
            <Link
              to="/terms-conditions#"
              className="hover:text-primary duration-300"
            >
              Terms of Use
            </Link>
          </li>
        </ul>

        <span>© 2021 All Rights Reserved.</span>
      </div>
    </div>
  );
};

export default BottombarNavigation;
