import Linkedin from "./Linkedin";
import BottombarNavigation from "./BottombarNavigation";

import { HashLink as Link } from "react-router-hash-link";

const Bottombar = (props) => {
  const { ...rest } = props;

  return (
    <footer {...rest}>
      <div className="container mx-auto">
        <div className="h-4-4 flex items-center justify-between">
          <Link to="/#about">
            <span className="cursor-pointer font-bold text-1-8 text-primary">
              Multi Task
            </span>
          </Link>

          <a
            href="https://www.linkedin.com/"
            target="_blank"
            className="hover:text-primary duration-300"
            rel="noreferrer"
          >
            <Linkedin />
          </a>
        </div>
      </div>

      <div className="border border-t border-gray">
        <div className="container mx-auto">
          <BottombarNavigation />
        </div>
      </div>
    </footer>
  );
};

export default Bottombar;
