import PropTypes from "prop-types";
import Button from "../../components/Button";

const Service = (props) => {
  const { title, description, to, ...rest } = props;

  return (
    <div {...rest}>
      <h3 className="text-3-6 sm:text-3-0 leading-tight pr-1-0">{title}</h3>
      <p className="mt-1-0 pr-1-0">{description}</p>
      <Button
        component={({ children, ...others }) => <a {...others}>{children}</a>}
        href={to}
        className="mt-4-0"
      >
        Show More
      </Button>
    </div>
  );
};

Service.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  to: PropTypes.string,
};

Service.defaultProps = {
  title: "",
  description: "",
  to: "",
};

export default Service;
