const ServicesDetails = (props) => {
  const { ...rest } = props;

  return (
    <section {...rest}>
      <div className="container mx-auto">
        <div className="py-8-0 sm:py-4-0">
          <div id="igaming-engine" className="flex sm:flex-col">
            <div
              style={{ backgroundImage: "url(/images/bg-2-1.jpg)" }}
              className="w-20-0 h-20-0 min-w-20-0 min-h-20-0 bg-cover bg-center rounded-full sm:hidden"
            ></div>

            <div className="pl-10-0 sm:pl-0">
              <h3 className="text-3-6 sm:text-3-0 leading-tight">
                IGaming Engine
              </h3>
              <div
                style={{ backgroundImage: "url(/images/bg-2-1.jpg)" }}
                className="sm:block hidden mt-2-0 -ml-2-0 -mr-2-0 h-20-0 bg-cover bg-center"
              ></div>
              <p className="mt-1-0 sm:mt-2-0">
                Gaming engine that allows creating simple Single-player like
                Slots and Blackjack as well as more complex Multiplayer Turn
                based games like Chess and Bridge, with client specific
                modifications for gambling and board games. Our high scalability
                allows operating same game on any number of platforms with any
                number of players while keeping the maintenance costs at their
                low end. Easy to integrate to any platform. Extremely intuitive
                coding with newest technologies for fast and easy addition of
                well-known games, as well as bringing new and innovative ideas
                to live.
              </p>
            </div>
          </div>

          <div id="backoffice-engine" className="flex pt-10-0 sm:pt-4-0">
            <div className="pr-10-0 sm:pr-0">
              <h3 className="text-3-6 sm:text-3-0 leading-tight">
                Back Office Engine
              </h3>
              <div
                style={{ backgroundImage: "url(/images/bg-4.png)" }}
                className="sm:block hidden mt-2-0 -ml-2-0 -mr-2-0 h-20-0 bg-cover bg-center"
              ></div>
              <p className="mt-1-0 sm:mt-2-0">
                Our back office is answer to every need gaming business may
                have. Our modular design allows integrating partial modules into
                existing system to improve performance and eliminate any manual
                force from platform management process. Existing module will
                take care of relations with partners, vendors, clients and more,
                can set up elaborate types of partnerships, bonuses for users
                and discounts for clients with many reporting options, advanced
                charting and statistical evaluations of platform performance.
                Anything your gaming business may require we can provide.
              </p>
            </div>

            <div
              style={{ backgroundImage: "url(/images/bg-4.png)" }}
              className="sm:hidden w-20-0 h-20-0 min-w-20-0 min-h-20-0 bg-cover bg-center rounded-full"
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesDetails;
