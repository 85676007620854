import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Topbar from "./Topbar";
// import SmothScroll from "../../components/SmothScroll";

const variants = {
  open: { y: 0 },
  closed: { y: "-100%" },
  hidden: { y: "-100%" },
};

const DefaultLayout = (props) => {
  const { children, ...rest } = props;
  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);

  const checkStickyHeaderVisibility = () => {
    if (window.scrollY > 80 && window.innerWidth > 768) {
      setIsStickyHeaderVisible(true);
    } else {
      setIsStickyHeaderVisible(false);
    }
  };

  useEffect(() => {
    checkStickyHeaderVisibility();

    window.addEventListener("scroll", checkStickyHeaderVisibility);
    window.addEventListener("resize", checkStickyHeaderVisibility);

    return () => {
      window.removeEventListener("scroll", checkStickyHeaderVisibility);
      window.removeEventListener("resize", checkStickyHeaderVisibility);
    };
  }, []);

  return (
    <div {...rest}>
      <div className="absolute z-20 left-0 top-0 w-full">
        <div className="container mx-auto">
          <Topbar className="text-white py-2-0" />
        </div>
      </div>

      <motion.div
        transition={{ ease: "linear", duration: 0.15 }}
        animate={isStickyHeaderVisible ? "open" : "closed"}
        initial="hidden"
        variants={variants}
        className="fixed z-20 left-0 top-0 w-full bg-white border-b border-gray"
      >
        <div className="container mx-auto">
          <Topbar className="py-1-0" color="white" />
        </div>
      </motion.div>

      {children}
    </div>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

DefaultLayout.defaultProps = {
  children: "",
};

export default DefaultLayout;
