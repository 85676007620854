const Logo = (props) => {
  // eslint-disable-next-line react/prop-types
  const { color } = props;

  return (
    <h1
      className={`cursor-pointer select-none py-0-5 px-2-0 font-bold rounded-full leading-tight ${
        color === "white"
          ? "text-white bg-primary text-2-4"
          : "text-primary bg-white text-3-0 sm:text-2-4 xs:text-2-2"
      }`}
    >
      <span className="block mt-0-1">Multi Task</span>
    </h1>
  );
};

export default Logo;
