import PropTypes from "prop-types";

const DataPolicyModule = (props) => {
  const { className, ...rest } = props;

  return (
    <main className={className} {...rest}>
      <div
        className="relative h-40-0 sm:h-30-0 bg-cover bg-center"
        style={{ backgroundImage: "url(/images/bg1.jpg)" }}
      >
        <div className="absolute inset-0 bg-primary opacity-50" />

        <div className="container mx-auto h-full">
          <div className="relative h-full flex justify-center flex-col">
            <span className="text-5-0 leading-tight text-white">
              Data policy
            </span>
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="py-5-0">
          <p>
            <strong>
              What personal information do we collect from the people that visit
              our website?
            </strong>
            <br />
            When filling the form or registering on our site, as appropriate,
            you may be asked to enter your name, email address, phone number or
            other details to help you with your experience.
          </p>
          <p className="pt-2-0">
            <strong>When do we collect information?</strong>
            <br />
            We collect information from you when you subscribe to a newsletter,
            fill out a form, enter information on our website or provide us with
            any feedback regarding our products or services.
          </p>
          <p className="pt-2-0">
            <strong>How do we use your information?</strong>
            <br />
            We may use the information we collect from you when you register,
            respond to a survey or marketing communication or surf the website
            to follow up with you after correspondence (live chat, email or
            phone inquiries) or sending important updates or email campaigns,
            which you subscribed to.
          </p>
          <p className="pt-2-0">
            <strong>Third-party disclosure</strong>
            <br />
            We do not sell, trade, or otherwise transfer to outside parties your
            Personally Identifiable Information unless we provide users with
            advance notice. We may also release information when it’s release is
            appropriate to comply with the law, enforce our site policies, or
            protect ours or other’s rights, property or safety.
            <br />
            However, non-personally identifiable visitor information may be
            provided to other parties for marketing, advertising, or other uses.
          </p>
          <p className="pt-2-0">
            <strong>We collect your email address in order to:</strong>
          </p>
          <ul className="list-disc p-3-0">
            <li>
              Send information, respond to inquiries, and/or other requests or
              questions;
            </li>
            <li>
              Process orders and to send information and updates pertaining to
              orders;
            </li>
            <li>
              Send you additional information related to your product and/or
              service;
            </li>
            <li>
              Market to our mailing list or continue to send emails to our
              clients after the original transaction has occurred.
            </li>
          </ul>
          <p>
            <strong>
              To be in accordance with CAN-SPAM, we agree to the following:
            </strong>
          </p>
          <ul className="list-disc p-3-0">
            <li>Not use false or misleading subjects or email addresses;</li>
            <li>
              Identify the message as an advertisement in some reasonable way;
            </li>
            <li>
              Include the physical address of our business or site headquarters;
            </li>
            <li>
              Monitor third-party email marketing services for compliance, if
              one is used;
            </li>
            <li>Honour opt-out/unsubscribe requests quickly;</li>
            <li>
              Allow users to unsubscribe by using the link at the bottom of each
              email.
            </li>
          </ul>
          <p>
            If at any time you would like to unsubscribe from receiving future
            emails, you can email us at{` `}
            <a
              className="text-primary underline"
              href="mailto:contact@mtask.ge"
            >
              <strong>contact@mtask.ge</strong>
            </a>
            {` `}
            or follow the instructions at the bottom of each email and we will
            promptly remove you from ALL of our correspondence.
          </p>
        </div>
      </div>
    </main>
  );
};

DataPolicyModule.propTypes = {
  className: PropTypes.string,
};

DataPolicyModule.defaultProps = {
  className: "",
};

export default DataPolicyModule;
