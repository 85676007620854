import Hero from "./Hero";
import ServicesList from "./ServicesList";
import ServicesDetails from "./ServicesDetails";
import Bottombar from "./Bottombar";

const HomeModule = (props) => {
  const { ...rest } = props;

  return (
    <main {...rest}>
      <Hero />
      <ServicesList />
      <ServicesDetails className="border-t border-gray" />
      <Bottombar className="border-t border-gray" />
    </main>
  );
};

export default HomeModule;
