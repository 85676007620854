/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

const Button = (props) => {
  const { children, component, className, ...rest } = props;

  const Component =
    component ||
    (({ children, ...rest }) => <button {...rest}>{children}</button>);

  return (
    <Component
      className={`inline-block px-6-0 py-1-0 rounded-4-0 bg-primary text-white ${className}`}
      {...rest}
    >
      {children}
    </Component>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.func,
  className: PropTypes.string,
};

Button.defaultProps = {
  component: null,
  className: "",
};

export default Button;
