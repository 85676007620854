import { Switch, Route } from "react-router-dom";
import HomePage from "../pages/Home";
import ContactPage from "../pages/Contact";
import DataPolicyPage from "../pages/DataPolicy";
import TermsConditionsPage from "../pages/TermsConditions";

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <HomePage />
    </Route>
    <Route path="/contact">
      <ContactPage />
    </Route>
    <Route path="/data-policy">
      <DataPolicyPage />
    </Route>
    <Route path="/terms-conditions">
      <TermsConditionsPage />
    </Route>
  </Switch>
);

export default Routes;
