import { HashLink as Link } from "react-router-hash-link";
import Logo from "../../components/Logo";

const Header = (props) => {
  // eslint-disable-next-line react/prop-types
  const { className, color } = props;

  return (
    <div className={`flex justify-between items-center ${className}`}>
      <Link to="/#about">
        <Logo color={color} />
      </Link>

      <ul className="flex">
        <li className="sm:hidden">
          <Link
            className="hover:text-primary text-current duration-300"
            to="/#about"
          >
            About Company
          </Link>
        </li>

        <li className="ml-4-5 sm:ml-0">
          <Link
            to="/#services-list"
            className="hover:text-primary text-current duration-300"
          >
            Our Services
          </Link>
        </li>

        <li className="ml-4-5 sm:ml-2-0">
          <Link to="/contact" className=" hover:text-primary duration-300">
            Contact us
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Header;
